<template>
  <div class="church-ministry template-1">
    <header class="page-header container">
      <h1>{{ translations.tcAuxiliaryCandidates }}</h1>
    </header>
    <!-- / Page Header -->
    <security-banner v-if="!allowPage" :i18n="i18nSecurityBanner"></security-banner>
    <div v-if="allowPage">
      <camp-selector @camp_changed="page_load()" :tooltip="translations.tcCurrentHome" :i18n="translations.components"></camp-selector>
      <!-- / Camp Selector -->

      <data-table
        :fields="fields"
        :items="translatedProspects"
        :csvUrl="csvUrl"
        :pdfUrl="pdfUrl"
        :reportName="reportName"
        :campType="campType"
        :noRecordsMessage="noRecordsMessage"
        individualType="prospect"
        :addItems="addItems"
        :i18n="translations.components"
        @addProspect="addProspect"
      ></data-table>
      <!-- / Data Tables -->

      <footer class="page-footer"></footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import DataTable from '@/components/DataTableMembers.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'auxiliary-candidates',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      translatedProspects: [],
      i18nSecurityBanner: { 'security-banner': {} },
      fields: [
        { key: 'sort_name', label: 'Name', sortable: true },
        { key: 'add_date', label: 'Add Date', sortable: true },
        { key: 'type', label: 'Type', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
      ],
      noRecordsMessage: 'No candidates were found.',
      addItems: {
        display: true,
        text: 'Add Candidate',
        action: 'addProspect',
      },
      reportName: 'AuxiliaryCandidatesByCamp'
    }
  },
  components: {
    campSelector: CampSelect,
    dataTable: DataTable,
    securityBanner: SecurityBanner,
  },
  methods: {
    ...mapActions({
      clearIndividualProfile: 'membership/clearIndividualProfile',
      clearMemberDefaultType: 'membership/clearMemberTypeDefault',
      clearProspectProfile: 'membership/clearProspectProfile',
      loadAuxiliaryCandidates: 'auxiliary/loadAuxiliaryCandidates',
      loadAuxiliaryCandidatesReportUrls: 'auxiliary/loadAuxiliaryCandidatesReportUrls',
      loadCampType: 'user/loadSelectedCampType',
      loadProspectListReportUrls: 'membership/loadProspectListReportUrls',
      setLoadingStatus: 'menu/setLoadingStatus',
      setMemberDefaultType: 'membership/loadMemberTypeDefault',
    }),
    addProspect() {
      this.clearIndividualProfile()
      this.clearProspectProfile()
      this.$router.push({ name: 'add-prospect' })
    },
    async page_load() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          this.loadAuxiliaryCandidatesReportUrls(),
          this.getViewTranslations(),
          this.getComponentTranslations('camp-select', 'data-table-members', 'security-banner'),
          this.clearMemberDefaultType(),
          this.setMemberDefaultType('678cfa9f-3ced-4880-ab74-6bad634aaf74'),
          this.loadAuxiliaryCandidates(),
          this.loadProspectListReportUrls(),
          this.loadCampType(),
        ]).then((results) => {
          (this.translations.components = results[2]),
          this.setLoadingStatus(false)
          this.loadTranslations()
        })
      }
      catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)        
      }
    },
    loadTranslations() {
      if (!this.translations || !this.prospects) return false

      this.translatedProspects = this.prospects.map(prospect => {
        const prospectType = `tc${prospect.type}`
        const typeText = this.translations.hasOwnProperty(prospectType) ? this.translations[prospectType] : prospect.type
        return { ...prospect, type: typeText, }
      })


      this.fields[0].label = this.translations.tcName
      this.fields[1].label = this.translations.tcAddDate
      this.fields[2].label = this.translations.tcType
      this.fields[3].label = this.translations.tcEmail
      this.noRecordsMessage = this.translations.tcNoCandidatesWereFound
      this.addItems.text = this.translations.tcAddCandidate
    },
  },
  computed: {
    ...mapGetters({
      campType: 'user/userSelectedCampType',
      isInMenu: 'menu/isInMenu',
      csvUrl: 'auxiliary/auxiliaryCandidatesCSVUrl',
      pdfUrl: 'auxiliary/auxiliaryCandidatesPDFUrl',
      prefCulture: 'user/userPreferredCulture',
      prospects: 'auxiliary/prospects',
      prospectListReportAsCSVUrl: 'membership/prospectListReportAsCSVUrl',
    }),
    allowPage() {
      return this.isInMenu(this.$route.path)
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';
</style>
